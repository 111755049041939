import { createRouter, createWebHistory } from 'vue-router'
import { getSearch, updateMeta } from '@/utils/utils.js'
import { getTypeList } from '@/hooks/useReportType'
import i18n from '@/lang/index.js'
import storage from '@/utils/storage.js'
import { path } from './constant'
import { pcRouter, pcRouterList } from './pcRouter'
import mitt from '@/plugins/mitt'
import {
  PLATE_ID,
  REPORT_TYPE,
  BIRTH_TYPE,
  BIRTH_TYPE_STR,
  UPDATE_ROUTER
} from '@/utils/contants'

const Home = () => import('@/views/Home/index.vue')
const ReportHome = () => import('@/views/ReportHome/index.vue')
const Report = () => import('@/views/Report/index.vue')
const SimpleReport = () => import('@/views/SimpleReport/index.vue')
const HiSeerCard = () => import('@/views/HiSeerCard/index.vue')
const PlateHome = () => import('@/views/PlateHome/index.vue')
const PlateDetail = () => import('@/views/PlateDetail/index.vue')
// 人格指南
const Personality = () => import('@/views/Report/views/Personality/index.vue')
const PersonalitySimple = () =>
  import('@/views/Report/views/Personality/views/Simple.vue')
const PersonalityDetail = () =>
  import('@/views/Report/views/Personality/views/Detail.vue')
// 命运指南
const DestinyHome = () => import('@/views/Report/views/Destiny/index.vue')
const DestinySimple = () =>
  import('@/views/Report/views/Destiny/views/Simple.vue')
const DestinyDetail = () =>
  import('@/views/Report/views/Destiny/views/Detail.vue')
// 恋爱指南
const LoveHome = () => import('@/views/Report/views/Love/index.vue')
const LoveSimple = () => import('@/views/Report/views/Love/views/Simple.vue')
const LoveDetail = () => import('@/views/Report/views/Love/views/Detail.vue')
// 人际指南
const RelationHome = () => import('@/views/Report/views/Relationship/index.vue')
const RelationSimple = () =>
  import('@/views/Report/views/Relationship/views/Simple.vue')
const RelationDetail = () =>
  import('@/views/Report/views/Relationship/views/Detail.vue')
// 职场指南
const ProfessionHome = () => import('@/views/Report/views/Profession/index.vue')
const ProfessionSimple = () =>
  import('@/views/Report/views/Profession/views/Simple.vue')
const ProfessionDetail = () =>
  import('@/views/Report/views/Profession/views/Detail.vue')
// 答案之书
const AnswerBook = () => import('@/views/AnswerBook/index.vue')
const AnswerShare = () => import('@/views/AnswerBook/Share.vue')
const AnswerDetail = () => import('@/views/AnswerBook/Detail.vue')
const AnswerPayment = () => import('@/views/AnswerBook/components/Payment.vue')
const LinkApp = () => import('@/views/LinkApp/index.vue')
// 资讯组件
const LongVideo = () => import('@/views/Information/LongVideo.vue')
const ShortVideo = () => import('@/views/Information/ShortVideo.vue')
const Book = () => import('@/views/Information/Book.vue')
const Article = () => import('@/views/Information/Article.vue')
const IVideo = () => import('@/views/Information/IVideo.vue')
// 宫合报告
const Match = () => import('@/views/match/Index.vue')
const MatchPayment = () => import('@/views/match/Payment.vue')
const MatchDetail = () => import('@/views/match/Detail.vue')
// 年运报告
const Annual = () => import('@/views/Annual/index.vue')
const AnnualDetail = () => import('@/views/Annual/Detail.vue')
const AnnualAddInfo = () => import('@/views/Annual/AddInfo.vue')
const AnnualSchedule = () => import('@/views/Annual/Schedule.vue')
const AnnualExpress = () => import('@/views/Annual/Express.vue')
const AnnualSwiper = () => import('@/views/OutPage/Swiper.vue')
const Hexagram = () => import('@/views/Hexagram/index.vue')
const MHexagram = () => import('@/views/Hexagram/Manual.vue')
const HexagramConsult = () => import('@/views/Hexagram/Consult.vue')
const HexagramInfo = () => import('@/views/Hexagram/Info.vue')
const HexagramDetail = () => import('@/views/Hexagram/Detail.vue')
const Enterprise = () => import('@/views/Enterprise/index.vue')
const EnterpriseDetail = () => import('@/views/Enterprise/Detail.vue')
const Career = () => import('@/views/Career/index.vue')
const CareerDetail = () => import('@/views/Career/Detail.vue')
const shareApp = () => import('@/views/OutPage/ShareApp.vue')

const Video = () => import('@/views/OutPage/Video.vue')

const validateType = async (idx) => {
  const typeList = storage.get(BIRTH_TYPE_STR) || (await getTypeList())
  const { type } = typeList[idx] || {}
  const _type = storage.get(REPORT_TYPE)
  console.log(type, _type)
  if (typeof _type !== 'number') return true
  return type === _type
}

const routes = [
  // 重定向原先home路由
  {
    path: '/',
    redirect: pcRouter.home
  },
  // pc路由
  ...pcRouterList,
  {
    path: path.home,
    redirect: pcRouter.home,
    beforeEnter: (to, home) => {
      updateMeta('title')
      updateTitle('HiSeer')
    }
  },
  // 生日报告
  {
    path: path.birth.home,
    beforeEnter: (to, home) => {
      updateMeta('birth_desc')
      updateTitle('menu.entire')
    },
    children: [
      {
        path: '',
        component: ReportHome
      },
      {
        path: path.birth.detail,
        component: Report,
        beforeEnter: (to, from) => {
          const { s = storage.get('s') } = getSearch()
          if (!validateType(BIRTH_TYPE.WHOLE) || !s)
            return router.replace(path.birth.home)
        }
      },
      {
        path: path.birth.simple,
        component: SimpleReport,
        beforeEnter: (to, from) => {
          if (!storage.get('s')) return router.replace(path.birth.home)
        }
      }
    ]
  },
  // 人格指南
  {
    path: path.personality.home,
    children: [
      { path: '', component: Personality },
      {
        path: path.personality.payment,
        component: PersonalitySimple,
        beforeEnter: (to, from) => {
          const s = storage.get('s')
          if (!validateType(BIRTH_TYPE.PERSON) || !s)
            return router.replace(path.personality.home)
        }
      },
      {
        path: path.personality.detail,
        component: PersonalityDetail,
        beforeEnter: (to, from) => {
          const { s = storage.get('s') } = getSearch()
          if (!validateType(BIRTH_TYPE.PERSON) || !s)
            return router.replace(path.personality.home)
        }
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.person')
    }
  },
  // 命运指南
  {
    path: path.destiny.home,
    children: [
      { path: '', component: DestinyHome },
      {
        path: path.destiny.payment,
        component: DestinySimple,
        beforeEnter: (to, from) => {
          const s = storage.get('s')
          if (!validateType(BIRTH_TYPE.DESTINY) || !s)
            return router.replace(path.destiny.home)
        }
      },
      {
        path: path.destiny.detail,
        component: DestinyDetail,
        beforeEnter: (to, from) => {
          const { s = storage.get('s') } = getSearch()
          if (!validateType(BIRTH_TYPE.DESTINY) || !s)
            return router.replace(path.destiny.home)
        }
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.destiny')
    }
  },
  // 恋爱指南
  {
    path: path.love.home,
    children: [
      { path: '', component: LoveHome },
      {
        path: path.love.payment,
        component: LoveSimple,
        beforeEnter: (to, from) => {
          const s = storage.get('s')
          if (!validateType(BIRTH_TYPE.LOVE) || !s)
            return router.replace(path.love.home)
        }
      },
      {
        path: path.love.detail,
        component: LoveDetail,
        beforeEnter: (to, from) => {
          const { s = storage.get('s') } = getSearch()
          if (!validateType(BIRTH_TYPE.LOVE) || !s)
            return router.replace(path.love.home)
        }
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.love')
    }
  },
  // 人际指南
  {
    path: path.relation.home,
    children: [
      { path: '', component: RelationHome },
      {
        path: path.relation.payment,
        component: RelationSimple,
        beforeEnter: (to, from) => {
          const s = storage.get('s')
          if (!validateType(BIRTH_TYPE.INTERPERSON) || !s)
            return router.replace(path.relation.home)
        }
      },
      {
        path: path.relation.detail,
        component: RelationDetail,
        beforeEnter: (to, from) => {
          const { s = storage.get('s') } = getSearch()
          if (!validateType(BIRTH_TYPE.INTERPERSON) || !s) {
            return router.replace(path.relation.home)
          }
        }
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.relation')
    }
  },
  // 职业指南
  {
    path: path.profession.home,
    children: [
      { path: '', component: ProfessionHome },
      {
        path: path.profession.payment,
        component: ProfessionSimple,
        beforeEnter: (to, from) => {
          const s = storage.get('s')
          if (!validateType(BIRTH_TYPE.CAREER) || !s)
            return router.replace(path.profession.home)
        }
      },
      {
        path: path.profession.detail,
        component: ProfessionDetail,
        beforeEnter: (to, from) => {
          const { s = storage.get('s') } = getSearch()
          if (!validateType(BIRTH_TYPE.CAREER) || !s)
            return router.replace(path.profession.home)
        }
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.profession')
    }
  },
  // 免费排盘
  {
    path: path.plate.home,
    beforeEnter: (to, home) => {
      updateMeta('plate_desc')
      updateTitle('menu.plate')
    },
    children: [
      {
        path: '',
        component: PlateHome
      },
      {
        path: path.plate.detail,
        component: PlateDetail,
        beforeEnter: (to, from) => {
          const s = storage.get(PLATE_ID)
          const search = getSearch()
          if (!s && !search?.name) return router.replace(path.plate.home)
        }
      }
    ]
  },
  // 答案之书
  {
    path: path.answer.home,
    children: [
      {
        path: '',
        component: AnswerBook
      },
      {
        path: path.answer.share,
        component: AnswerShare
      },
      {
        path: path.answer.detail,
        component: AnswerDetail
      },
      {
        path: path.answer.payment,
        component: AnswerPayment
      }
    ],
    beforeEnter: () => {
      updateMeta('birth_desc')
      updateTitle('menu.answer')
    }
  },
  // 宫合报告
  {
    path: path.match.home,
    children: [
      {
        path: '',
        component: Match
      },
      {
        path: path.match.payment,
        component: MatchPayment
      },
      {
        path: path.match.detail,
        component: MatchDetail
      }
    ],
    beforeEnter: () => {
      updateMeta('birth_desc')
      updateTitle('menu.match')
    }
  },
  // 年运报告
  {
    path: path.annual.home,
    children: [
      {
        path: '',
        component: Annual
      },
      {
        path: path.annual.info,
        component: AnnualAddInfo
      },
      // {
      //   path: path.annual.express,
      //   component: AnnualExpress
      // },
      // 开运符进度
      // {
      //   path: path.annual.schedule,
      //   component: AnnualSchedule
      // },
      {
        path: path.annual.detail,
        component: AnnualDetail
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.annual')
    }
  },
  // 问卦咨询
  {
    path: path.hexagram.home,
    children: [
      {
        path: '',
        component: Hexagram
      },
      // {
      //   path: path.hexagram.mHexagram,
      //   component: MHexagram
      // },
      {
        path: path.hexagram.consult,
        component: HexagramConsult
      },
      {
        path: path.hexagram.info,
        component: HexagramInfo
      },
      {
        path: path.hexagram.detail,
        component: HexagramDetail
      }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.hexagram')
    }
  },
  // 职能报告
  {
    path: path.enterprise.home,
    children: [
      { path: '', component: Enterprise },
      { path: path.enterprise.detail, component: EnterpriseDetail }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.enterprise')
    }
  },
  {
    path: path.career.home,
    children: [
      { path: '', component: Career },
      { path: path.career.detail, component: CareerDetail }
    ],
    beforeEnter: (to, home) => {
      updateTitle('menu.career')
    }
  },
  {
    path: path.share,
    component: HiSeerCard,
    beforeEnter: (to, from) => {
      const search = getSearch()
      if (!search['s']) return router.replace('/')
      updateTitle('HiSeer')
    }
  },
  // 资讯路由
  {
    path: path.information.home,
    children: [
      {
        path: '',
        component: LongVideo
      },
      {
        path: path.information.long,
        component: LongVideo
      },
      {
        path: path.information.short,
        component: ShortVideo
      },
      {
        path: path.information.book,
        component: Book
      },
      {
        path: path.information.article,
        component: Article
      },
      {
        path: path.information.iVideo,
        component: IVideo
      }
    ],
    beforeEnter: () => {
      updateMeta('birth_desc')
      updateTitle('HiSeer')
    }
  },
  // 打开app
  {
    path: path.linkApp,
    component: LinkApp,
    beforeEnter: () => {
      updateTitle('HiSeer')
    }
  },
  {
    path: path.android,
    component: () => import('@/views/LinkApp/android.vue')
  },
  {
    path: path.mainland,
    component: () => import('@/views/LinkApp/mainland.vue')
  },
  // 视频预览
  {
    path: path.video,
    component: Video,
    beforeEnter: () => {
      updateTitle('HiSeer')
    }
  },
  {
    path: path.swiper,
    component: AnnualSwiper,
    beforeEnter: () => {
      updateTitle('HiSeer')
    }
  },
  // 下载app
  {
    path: path.shareApp,
    component: shareApp,
    beforeEnter: () => {
      updateTitle('HiSeer')
    }
  },
  // 兼容原先的链接分享
  { path: '/report', redirect: path.birth.detail },
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: path.home }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to) => {
    if (to.hash) {
      console.log(window.screen.width)

      document.querySelector(to.hash).scrollIntoView({
        behavior: 'smooth',
        block: window.screen.width > 780 ? 'start' : 'center'
      })
    }
  },
  routes
})

const getTitle = () => {
  const { pathname } = location
  if (pathname.includes(path.birth.home)) return i18n.global.t('title_birth')
  if (pathname.includes(path.plate.home)) return i18n.global.t('title_plate')
  return i18n.global.t('title')
}

const updateTitle = (key) => {
  document.title = i18n.global.t(key)
}

router.beforeEach((to, from) => {
  mitt.emit(UPDATE_ROUTER, to.path)
})

router.afterEach((to, from) => {
  // document.title = getTitle()
})
export default router
